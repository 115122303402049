body,
textarea {
    font-family: 'Open Sans', sans-serif !important;
    color: #2C2F30;
}

::placeholder {
    color: #C0BFBF;
}

.tituloSeccion {
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 66px;
    color: #2C2F30;
    letter-spacing: 1px;
    margin-bottom: 40px;
}

.cajaInfo {
    width: 100%;
    border: 3px solid #F3F3F3;
    border-radius: 20px;
}

.letraCourier {
    font-family: 'Courier Prime', monospace;
}

.inicioDepartamento {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #21B2BB;
}

.tarjetaDepartamento {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #21B2BB;
}

.tituloApartado {
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 50px;
    letter-spacing: 1px;

    color: #2C2F30;
}

.upperImg {
    text-transform: uppercase;
    position: absolute;
    background-color: #21B2BB;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 7px 12px;
}

.circuloAvatar {
    width: 100%;
    border: 3px solid rgb(163, 163, 163);
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    top: 0px;
    max-width: 136px;
    max-height: 136px;
    height: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.rectanguloTarjeta {
    border: 3px solid rgb(163, 163, 163);
    margin-top: 70px;
    padding: 65px 25px 30px;
    position: relative;
    z-index: 3;
    background-color: #fff;
    border-radius: 20px;
}

.imgAvatarTarjeta {
    max-width: 136px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 100%;
    position: absolute;
    z-index: 4;
    top: 4px;
    left: 4px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.tarjetaValidacion .circuloAvatar {
    width: 100%;
    border: 3px solid rgb(163, 163, 163);
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    top: 0px;
    max-width: 100px;
    max-height: 100px;
    height: 100%;
    left: 0;
    right: 0;
    margin: 0 50px;
}

.tarjetaValidacion .rectanguloTarjeta {
    border: 3px solid rgb(163, 163, 163);
    margin-top: 50px;
    padding: 50px 25px 10px;
    position: relative;
    z-index: 3;
    background-color: #fff;
    border-radius: 20px;
}

.tarjetaValidacion .imgAvatarTarjeta {
    max-width: 100px;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 100%;
    position: absolute;
    z-index: 4;
    top: 4px;
    left: 0;
    right: 0;
    margin: 0 53px;
}

.tituloTarjeta {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;

    color: #2C2F30;
}

.tituloReconocimientos {
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 34px;
    letter-spacing: 1px;
    color: #000000;
}

.txtBotonReconocimientos {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
}

.ganadoresNombre {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
}

.ganadoresDepartamento {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
}

.caritaPuntos:hover {
    opacity: 1 !important;
}

.razona {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
}

.tituloValoracion {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 34px;
    color: #2C2F30;
}

.numValoracion {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 34px;

    color: #000000;
}

.puntosValoracion {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;

    color: #000000;
}

.footer {
    background-color: #21B2BB;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #FFFFFF;
    padding: 10px 0px;
}

.loginTitulo {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 84px;
    letter-spacing: 1px;

    color: #2C2F30;
}

.loginSubtitulo {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;

    color: #2C2F30;
}

.loginTexto {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;

    color: #464F60;
}

.loginLabel {
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 10px;

    color: #2C2F30;
}

.loginHelp {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    color: #6E768E;
}


@media (max-width: 899px) {
    .circuloAvatar {
        width: 100%;
        border: 3px solid rgb(163, 163, 163);
        border-radius: 50%;
        position: absolute;
        z-index: 1;
        top: 0px;
        max-width: 100px;
        max-height: 100px;
        height: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .rectanguloTarjeta {
        border: 3px solid rgb(163, 163, 163);
        margin-top: 50px;
        padding: 50px 10px 10px;
        position: relative;
        z-index: 3;
        background-color: #fff;
        border-radius: 20px;
    }

    .imgAvatarTarjeta {
        max-width: 100px;
        width: 100%;
        background-color: rgb(255, 255, 255);
        border-radius: 100%;
        position: absolute;
        z-index: 4;
        top: 4px;
        left: 4px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}